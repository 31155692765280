import React, { useState } from 'react';
import { navigate } from 'gatsby';
import Head from '../components/head';
import { handleLogin } from '../services/auth';
import { getReferrerLink } from '../services/url-referrer';
import { Breadcrumb } from 'gatsby-plugin-breadcrumb';

const Login = ({ location }) => {
    const [ formState, setFormState ] = useState(getInitialState());
    
    function getInitialState() {
      return {
      email: '',
      password: '',
      errors: ''
    };
  }

    const handleChange = e => {
      setFormState(prevState => ({
        ...prevState,
        errors: '',
        [ e.target.name]: e.target.value 
      }));
    };

    const handleSubmit = async e => {
      e.preventDefault();
      const { email, password } = formState;
      try {
        await handleLogin(email, password);
        setFormState(getInitialState());
        const referrerLink = getReferrerLink();
        navigate(referrerLink || '/');
      } catch (error) {
        setFormState({...getInitialState(), errors: error.message });
      }
    };  

    return (
        <>
            <Head 
              pageTitle="Please Login" 
              location={location}
              description={`Please login to access curriculum`} 
            />
            <Breadcrumb 
              location={location} 
              crumbLabel={"Login"}
            />
            <main className="login-content slow-fade">
              <section>
                <h3>Please Login</h3>
                <form onSubmit={handleSubmit} className="login-form">
                  <label>
                    Username:
                    <input 
                      name="email" 
                      type="email" 
                      value={formState.email} 
                      onChange={handleChange}  
                    />
                  </label>  
                  <label>
                    Password:
                    <input 
                      name="password" 
                      type="password" 
                      value={formState.password} 
                      onChange={handleChange}  
                    />
                  </label>
                  <input type="submit" value="Login" />  
                </form> 
              </section>
                  <div style={{height: '5rem', marginTop: '3rem', maxWidth: '23rem'}}>
                  { formState.errors && <div style={{color: 'red'}}>{formState.errors}</div> }
                  </div> 
            </main>
        </>
    );
};

export default Login;
